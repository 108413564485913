import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 5×2\\@90% 1RM`}</p>
    <p>{`Pendlay Rows 5×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`20-15-10-5 reps each:`}</p>
    <p>{`Thrusters (115/75) (RX+ 135/95)`}</p>
    <p>{`Box Jumps (24/20) (RX+ 30/24″)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The signup sheet for Memorial Day Murph is up next to the white
board at the gym.  We’ll have heats starting every :30 beginning at
9:00am.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      